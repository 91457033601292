export const availableReportsCardsProps = {
    overlappingReport: {
        id: "overlapping-report",
        num: "1",
        title: "Audience overlapping",
        description: "Отчет по\u00A0пересечению аудиторий",
        list: ["Выводится %\u00A0пересечения аудитории по\u00A0площадкам («Sites/Offers»)"],
        cardLink: "/auditoria-overlapping",
    },
    frequencyReport: {
        id: "frequency-report",
        num: "2",
        title: "Frequency",
        description: "Распределение по частотам",
        list: ["охвата по частоте", "кликов по частоте", "конверсий по частоте"],
        cardLink: "/frequency-report",
    },
    technologyReport: {
        id: "technology-report",
        num: "3",
        title: "Technologies",
        description: "Отчет по технологиям",
        list: ["Отчет по девайсам", "Отчет по операционным системам", "Отчет по браузерам", "Отчет по интернет-провайдерам"],
        cardLink: "/technology-report",
    },
    auditoriaReport: {
        id: "auditoria-report",
        num: "4",
        title: "Audience",
        description: "Отчет по аудитории",
        list: ["Отчет по полу", "Отчет по возрасту", "Отчет по интересам", "My Audiences"],
        cardLink: "/auditoria-report",
    },
    geoReport: {
        id: "geo-report",
        num: "5",
        title: "Geographic",
        description: "Отчет по\u00A0географическому распределению",
        list: ["Отчет по странам", "Отчет по макрорегионам России"],
        cardLink: "/geo-report",
    },
};
