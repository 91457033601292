export const wcmStructureCardsProps = {
    campaigns: {
        id: "campaigns",
        num: "1",
        title: "Campaigns",
        description: "Кампании",
        list: ["Первичный мониторинг результатов размещения", "Структура рекламных кампаний"],
        href: "/campaigns",
        name: "Campaigns",
        href2: "/campaign-structure",
        name2: "Просмотр структуры кампании",
        href3: "/campaign-report",
        name3: "Отчет по кампании",
    },
    creativeLibrary: {
        id: "creative-library",
        num: "2",
        title: "Creative library",
        description: "Библиотека креативов",
        list: ["Добавление креативов", "Управление креативами (в\u00A0т.ч. динамическими)"],
    },
    conversions: {
        id: "conversions",
        num: "3",
        title: "Conversions",
        description: "Конверсии",
        list: ["Создание страниц конверсий", "Управление страницами конверсий"],
        cardLink: "/conversion-report",
    },
    reporting: {
        id: "reporting",
        num: "4",
        title: "Reporting",
        description: "Отчеты",
        list: ["Подробный мониторинг размещений", "Создание расширенных отчетов по\u00A0компании"],
        cardLink: "/reporting",
    },
    reportCenter: {
        id: "report-center",
        num: "5",
        title: "Report center",
        description: "Шаблоны отчетов",
        list: ["Создание шаблонов отчетов", "Автоматическая отправка отчетов по\u00A0расписанию"],
        cardLink: "/automatization",
    },
};
