export const auditoriaOverlapingCardProps = {
    byCampaigns: {
        id: "bycampaigns",
        num: "1",
        title: "By Campaigns",
        description: "Отчет по пересечению между кампаниями",
        list: ["Выводится % пересечения аудитории по кампаниям («Campaigns») в рамках выбранного проекта («Project»)"],
        cardLink: "/bycampaigns",
    },
    bySites: {
        id: "bysites",
        num: "2",
        title: "By Sites",
        description: "Отчет по пересечению между площадками",
        list: ["Выводится % пересечения аудитории по площадкам («Sites/Offers») в рамках выбранной кампании («Campaign»)"],
        cardLink: "/bysites",
    },
    byInsertions: {
        id: "byinsertions",
        num: "3",
        title: "By Insertions",
        description: "Отчет по пересечению между инсершинами",
        list: ["Выводится % пересечения аудитории по площадкам («Insertions») в рамках выбранного проекта(«Site/Offer»)"],
        cardLink: "/byinsertions",
    },
};
