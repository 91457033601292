import React from "react";
import { sectionsTexts } from "../../props/sectionsTexts";
import { SectionHeading } from "../../components/sectionHeading";
import { RedWords } from "../../components/redWords";
import { Link } from "../../components/links";
import { Image } from "../../components/image";

export class ByInsertions extends React.Component {
    render() {
        return (
            <div className="section__wrapper">
                <section className="section section__not-homepage" id="available-reports">
                    <SectionHeading prop={sectionsTexts.byInsertions} />
                    <div className="section__content">
                        <p className="section__lead">Для выгрузки отчета по пересечению аудитории между инсершинами в рамках выбранной площадки необходимо:</p>
                        <ol className="section__list">
                            <li className="section__list-item">
                                Зайти в&nbsp;основную вкладку <Link prop={{ text: "«Reporting»", href: "/reporting" }} />;
                            </li>
                            <li className="section__list-item">
                                Выбираем в таблице под графиком нужный проект «<strong>Projects</strong>» и кликаем по нему; 
                            </li>
                            <li className="section__list-item">
                                Проваливаемся внутрь нужного проекта и в таблице под графиком кликаем на «<strong>Display</strong>»;
                            </li>
                            <li className="section__list-item">
                                Выбираем в таблице под графиком нужную рекламную кампанию «<strong>Campaigns</strong>» (Попадаем в&nbsp;подробный отчет с&nbsp;разбивкой
                                по&nbsp;структуре&nbsp;&mdash; <Link prop={{ text: "«Campaign tree»", href: "/campaign-tree" }} />);
                            </li>
                            <li className="section__list-item">
                                Под графиком (над таблицей) необходимо кликнуть на заголовок «View» — откроется дополнительное меню, в котором выбираем режим отображения «Standart»;
                            </li>
                            <li className="section__list-item">
                                Выбираем в таблице под графиком нужную площадку «Site» и кликаем по ней;
                            </li>
                            <li className="section__list-item">
                                В открывшемся уровне под графиком(над таблицей) необходимо кликнуть на заголовок «View» — откроется дополнительное меню с видами представления данных и дополнительными настройками;
                            </li>
                            <li className="section__list-item">
                                В&nbsp;строке меню <RedWords prop={"«Pivot Table»"} /> в&nbsp;окошке select dimension выбираем{" "}
                                <RedWords prop={"«Audience Overlapping»"} />;
                            </li>
                            <li className="section__list-item">
                                Кликаем на&nbsp;кнопку <RedWords prop={"«Apply»"} />.
                            </li>
                        </ol>
                    </div>

                    <Image prop={sectionsTexts.byInsertions.image} />
                    <div className="section__content">
                        <p className="section__lead">
                            Отчет представляет собой таблицу с наложением пересечения аудитории между инсершинами в процентах (в рамках выбранной площадки «<strong>Site</strong>»).
                        </p>
                    </div>

                    <Image prop={sectionsTexts.byInsertions.imageTwo} />
                </section>
            </div>
        );
    }
}
